<template>
  <div
    v-for="(bundle, bundleIndex) in cartStore.cart?.bundles"
    :key="bundleIndex"
    class="relative"
  >
    <div v-if="!bundle">
      <common-loader class="mx-auto my-16 max-h-16 md:max-h-24" />
    </div>

    <div v-if="bundle.products.length" class="flex flex-col gap-2 pb-2">
      <div v-for="(product, index) in bundle.products" :key="product.id">
        <CartItem v-if="!quickView" :item="product" :bundles="true" />
        <QuickViewItem v-else :item="product" :bundles="true" />
        <div
          v-if="index != bundle.products.length - 1"
          class="flex w-fit px-10"
          :class="quickView ? 'md:px-7' : 'md:px-14'"
        >
          <IconsPlusIcon />
        </div>
      </div>
    </div>

    <div
      v-if="bundle.variants.length > 0 && bundle.products.length > 0"
      class="flex w-fit px-10"
      :class="quickView ? 'md:px-7' : 'md:px-14'"
    >
      <IconsPlusIcon />
    </div>

    <div v-if="bundle.variants.length" class="flex flex-col gap-2 pb-2">
      <div v-for="(variant, index) in bundle.variants" :key="variant.id">
        <CartItem v-if="!quickView" :item="variant" :bundles="true" />
        <QuickViewItem v-else :item="variant" :bundles="true" />
        <div
          v-if="index != bundle.variants.length - 1"
          class="flex w-fit px-10"
          :class="quickView ? 'md:px-7' : 'md:px-14'"
        >
          <IconsPlusIcon />
        </div>
      </div>
    </div>

    <IconsXIcon
      class="absolute top-0 cursor-pointer ltr:right-0 rtl:left-0"
      @click="handleRemoveQuantity(bundle, bundleIndex)"
    />

    <div class="flex flex-col items-end justify-end">
      <div class="flex flex-col items-center pb-2">
        <div class="bundles__actions__price">
          {{ bundle.original_price }}
        </div>
        <div class="bundles__actions__originalPrice">
          {{ bundle.price }}
        </div>
      </div>
      <CommonNumberSpinner
        v-if="!quickView"
        v-model="bundle.quantity"
        class="origin-left md:origin-right"
        :max="bundle.stock"
        style="transform: scale(0.8)"
        @change="handleUpdateQuantity(bundle, $event)"
      />
    </div>

    <hr
      v-if="
        bundleIndex != cartStore.cart?.bundles?.length! - 1 ||
        cartStore.cart?.products
      "
      class="my-4"
    />

    <CartPrompt
      v-if="showPrompt && activeIndex == bundleIndex"
      @closePrompt="showPrompt = false"
      @removeQuantity="handleUpdateQuantity(bundle)"
    />
  </div>
</template>

<script setup lang="ts">
import type { Bundle } from "~/types/InformativePages/Header/CategoryBundleBrand";
import { ProductTypes } from "~/constants/ProductManagement/productTypes";
import { useCartStore } from "~/store/Features/Checkout/cartStore";
import QuickViewItem from "~/components/features/Checkout/CartQuickView/QuickViewItem.vue";
import CartItem from "./CartItem.vue";
import CartPrompt from "./CartPrompt.vue";

const props = defineProps(["quickView"]);

const cartStore = useCartStore();
const showPrompt = ref(false);
const activeIndex = ref(-1);

const handleUpdateQuantity = async (bundle: Bundle, event: number = 0) => {
  await cartStore?.updateQuantity(ProductTypes.BUNDLE, event, bundle.id);
  showPrompt.value = false;
};
const handleRemoveQuantity = async (bundle: Bundle, bundleIndex: number) => {
  if (props.quickView) {
    await handleUpdateQuantity(bundle);
  } else {
    showPrompt.value = true;
    activeIndex.value = bundleIndex;
  }
};
</script>
