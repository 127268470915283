<template>
  <div class="product-card flex w-full flex-row gap-x-3">
    <NuxtLink
      class="product-img !h-24 !w-24"
      :to="{
        path: `${paths.products.index}/${variantItem?.product_id ?? productItem?.id}`,
      }"
    >
      <CommonCardImage
        :alt="item.title"
        :firstImage="productItem?.featured_image ?? variantItem?.image"
        :secondImage="
          productItem?.second_image ??
          (variantItem?.media && variantItem?.media.length > 1
            ? variantItem?.media[1]
            : variantItem?.image)
        "
      />
    </NuxtLink>
    <div class="flex min-h-24 w-full flex-col justify-between">
      <div class="title flex w-full items-start justify-between">
        <NuxtLink
          class="h2 line-clamp-1 truncate"
          :to="{
            path: `${paths.products.index}/${variantItem?.product_id ?? productItem?.id}`,
          }"
        >
          {{ variantItem?.product_title ?? productItem?.title }}
        </NuxtLink>
        <IconsXIcon
          v-if="!bundles"
          class="w-5 cursor-pointer pt-1"
          @click="handleRemoveQuantity"
        />
      </div>
      <div class="flex items-end justify-between">
        <div class="flex flex-col gap-2">
          <CartItemOptions v-if="isVariant" :product="variantItem" />

          <div class="h5 uppercase opacity-[60%]">
            {{ $t("cart.qty") }} {{ item.quantity ? item.quantity : 1 }}
          </div>
        </div>
        <div v-if="!bundles" class="h2 whitespace-nowrap px-2 !font-bold">
          <div class="flex flex-col items-center justify-end py-2">
            <p v-if="item?.sale_price" class="text-warning md:text-lg">
              {{ item.sale_price }}
            </p>
            <p
              class="h2 !font-bold md:!font-normal"
              :class="{
                '!text-sm line-through md:!text-base md:!font-medium':
                  item?.sale_price,
              }"
            >
              {{ item.price }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Product } from "~/types/ProductManagement/Product";
import type { Variant } from "~/types/ProductManagement/variant";
import paths from "~/constants/paths";
import { useCartStore } from "~/store/Features/Checkout/cartStore";
import { ProductTypes } from "~/constants/ProductManagement/productTypes";
import CartItemOptions from "../CartPage/CartItemOptions.vue";

const props = defineProps({
  item: {
    type: Object as PropType<Product | Variant>,
    required: true,
  },
  bundles: {
    type: Boolean,
    required: false,
  },
});

const cartStore = useCartStore();

const isVariant = computed(() => {
  return (props.item as Variant).product_id !== undefined;
});

const productItem = computed(() => {
  if (!isVariant.value) {
    return props.item as Product;
  }
});

const variantItem = computed(() => {
  if (isVariant.value) {
    return props.item as Variant;
  }
});

const handleRemoveQuantity = async () => {
  await cartStore.updateQuantity(
    isVariant.value ? ProductTypes.VARIANT : ProductTypes.PRODUCT,
    0,
    props.item.id,
  );
};
</script>

<style scoped>
.title.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 60%;
}
@media (min-width: 640px) {
  .title.truncate {
    max-width: 80%;
  }
}
</style>
