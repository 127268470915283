<template>
  <div class="flex w-full gap-6">
    <CommonSkeletonLoader class="h-36 w-44 md:h-44 md:w-52" />
    <div class="flex w-full flex-col justify-between gap-3">
      <div class="flex w-full flex-col gap-4">
        <CommonSkeletonLoader class="h-6 w-full" />
        <div class="flex w-full items-center gap-3">
          <CommonSkeletonLoader class="h-6 w-24 sm:w-1/4" />
          <CommonSkeletonLoader class="h-8 w-10" />
        </div>
        <div class="flex w-full items-center gap-3">
          <CommonSkeletonLoader class="h-6 w-24 sm:w-1/4" />
          <CommonSkeletonLoader class="h-8 w-8 rounded-full" />
        </div>
      </div>
      <div class="flex w-full items-center justify-between">
        <CommonSkeletonLoader class="h-6 w-14 sm:w-20" />
        <CommonSkeletonLoader class="h-8 w-16 sm:w-24" />
      </div>
    </div>
  </div>
</template>
