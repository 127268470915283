<template>
  <div>
    <div
      class="absolute left-0 top-0 flex h-full w-full flex-col items-center justify-center bg-white/95"
    >
      <div class="h3 text-center text-sm md:text-base">
        {{ $t("cart.sure_to_remove") }}
      </div>
      <button
        @click="emits('removeQuantity')"
        class="btn-secondary z-50 mt-6 h-[2.4rem] w-full max-w-[15rem]"
        data-cy="yes-remove-it-btn"
      >
        {{ $t("cart.remove_it") }}
      </button>
      <button
        @click="emits('closePrompt')"
        class="btn-primary mt-2 h-[2.4rem] w-full max-w-[15rem]"
      >
        {{ $t("cart.keep_it") }}
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
defineProps({ showPrompt: { type: Boolean, default: false } });
const emits = defineEmits(["closePrompt", "removeQuantity"]);
</script>
