<template>
  <div
    v-if="!isItemLoading"
    data-cy="item"
    class="product-card relative min-h-36"
  >
    <div class="flex flex-row gap-6">
      <NuxtLink
        class="product-img flex h-36 w-28 md:h-44 md:w-36"
        :to="{
          path: `${paths.products.index}/${variantItem?.product_id ?? productItem?.id}`,
        }"
      >
        <CommonCardImage
          :alt="item.title"
          :firstImage="productItem?.featured_image ?? variantItem?.image"
          :secondImage="
            productItem?.second_image ??
            (variantItem?.media && variantItem?.media.length > 1
              ? variantItem?.media[1]
              : variantItem?.image)
          "
        />
      </NuxtLink>
      <div
        class="flex h-full min-h-36 flex-grow flex-col justify-between gap-3 md:min-h-40"
      >
        <div class="flex flex-col gap-y-2">
          <div class="flex justify-between">
            <NuxtLink
              :to="{
                path: `${paths.products.index}/${variantItem?.product_id ?? productItem?.id}`,
              }"
              class="h2 line-clamp-1 block"
            >
              {{ variantItem?.product_title ?? productItem?.title }}
            </NuxtLink>
            <div v-if="!bundles" class="flex items-center gap-x-2">
              <CommonLike
                :productID="variantItem?.product_id ?? productItem?.id"
              />

              <IconsXIcon
                data-cy="remove-btn"
                class="cursor-pointer"
                @click="showPrompt = true"
              />
            </div>
          </div>
          <CartItemOptions v-if="isVariant" :product="variantItem" />
        </div>

        <div v-if="!bundles" class="items-end justify-between md:flex">
          <CommonCardPrice :price="item.price" :sale="item?.sale_price" />
          <CommonNumberSpinner
            v-model="item.quantity"
            class="origin-left md:origin-right"
            style="transform: scale(0.8)"
            :max="item.stock"
            @change="handleUpdateQuantity($event)"
          />
        </div>
      </div>
    </div>
    <div class="flex w-full items-center justify-end md:justify-start">
      <div
        v-if="
          item?.custom_fields?.back_image || item?.custom_fields?.front_image
        "
        @click="showPreview = true"
        class="rounded-auto h5 btn-secondary w-[61%] p-2 md:w-36"
      >
        {{ $t("cart.preview_design") }}
      </div>
    </div>
    <CartPrompt
      v-if="showPrompt"
      @removeQuantity="handleUpdateQuantity"
      @closePrompt="showPrompt = false"
    />
    <PreviewModal
      v-if="showPreview"
      :product="item"
      :show-preview="showPreview"
      @close="showPreview = false"
      :enableBackgroundClose="true"
    />
  </div>

  <CartItemLoader v-else />
</template>

<script setup lang="ts">
import type { Product } from "~/types/ProductManagement/Product";
import type { Variant } from "~/types/ProductManagement/variant";
import { ProductTypes } from "~/constants/ProductManagement/productTypes";
import { useCartStore } from "~/store/Features/Checkout/cartStore";

import CartItemLoader from "./CartItemLoader.vue";
import paths from "~/constants/paths";
import CartPrompt from "./CartPrompt.vue";
import CartItemOptions from "./CartItemOptions.vue";
import PreviewModal from "../../CustomDesign/PreviewModal.vue";

const props = defineProps({
  item: {
    type: Object as PropType<Product | Variant>,
    required: true,
  },
  bundles: {
    type: Boolean,
    default: false,
  },
});

const cartStore = useCartStore();

const showPreview = ref(false);
const showPrompt = ref(false);
const isItemLoading = ref(false);

const isVariant = computed(() => {
  return (props.item as Variant).product_id !== undefined;
});

const productItem = computed(() => {
  if (!isVariant.value) {
    return props.item as Product;
  }
});

const variantItem = computed(() => {
  if (isVariant.value) {
    return props.item as Variant;
  }
});

const handleUpdateQuantity = async (event: number = 0) => {
  isItemLoading.value = true;
  await cartStore.updateQuantity(
    isVariant.value ? ProductTypes.VARIANT : ProductTypes.PRODUCT,
    event,
    props.item.id,
  );
  isItemLoading.value = false;
};
</script>
